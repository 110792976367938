@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/spacings.scss';

$size: 18px;

.container {
    background-color: $color-input-background;
    color: $color-text;

    display: inline-block;
    border-radius: 2px;
    width: $size;
    height: $size;
    overflow: hidden;
    position: relative;

    &,
    & > * {
        cursor: pointer;
    }

    transition: all 0.2s ease;
    &:hover {
        transform: scale(1.1);
    }
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    width: $size;
    height: $size;
    margin: 0;
    opacity: 0;
}

.icon {
    position: absolute;
    top: 1px;
    left: 1px;
    width: $size - 2;
    height: $size - 2;
    color: $color-input-background;
    transition: color 0.2s ease;

    .checked & {
        color: white;
    }
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    transition: border 0.2s ease;
    border: 1px solid $color-border;

    .checked & {
        border: $size/2 solid $color-green-500;
    }
}

.wrapper {
    position: relative;
    cursor: default;
    margin-left: $spacing-5;

    .container {
        position: absolute;
        left: -$size - 10px;
        top: 1px;
    }
}

.label {
    display: inline-block;
}
