@import 'styles/palette.scss';

$color-navigation-dark: #272b35;
$color-navigation-medium: #292e38;
$color-navigation-light: #2b303b;
$color-navigation-border: #232730;

$color-background: var(--background);
$color-border: var(--border);

$color-text: var(--color);
$color-link: var(--color-link);
$color-link-hover: var(--color-link-hover);

$color-text-dark: var(--color-dark);
$color-link-dark: var(--color-dark-link);
$color-link-hover-dark: var(--color-dark-link-hover);

$color-green-400: $palette-green-400;
$color-green-500: $palette-green-500;
$color-green-600: $palette-green-600;

$color-light-400: var(--light-400);
$color-light-500: var(--light-500);
$color-light-600: var(--light-600);

$color-dark-400: var(--dark-400);
$color-dark-500: var(--dark-500);
$color-dark-600: var(--dark-600);

$color-light-shadow: $palette-light-shadow;
$color-dynamic-light-shadow: var(--light-shadow);

$color-dark-shadow: $palette-dark-shadow;
$color-dynamic-dark-shadow: var(--dark-shadow);

$color-input-background: var(--input-background);
