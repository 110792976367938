.center {
    text-align: center;
}

.bolder {
    font-weight: 600;
}

h1,
h2,
h3,
h4 {
    .bolder {
        font-weight: bolder;
    }
}

.spacer {
    border: none;
}
