@import 'styles/colors.scss';

$topbarHeight: 60px;
$spacing: 0px;

.wrapper {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: $spacing;
    grid-auto-rows: $topbarHeight calc(100vh - #{$topbarHeight} - #{$spacing});
}

.topbar {
    grid-column: 1 / 3;
    grid-row: 1;
    border-bottom: 1px solid #3f444e;

    text-align: center;
}

.sidebar {
    grid-column: 1;
    grid-row: 2;
    border-right: 1px solid #3f444e;
    overflow: auto;
}

.content {
    grid-column: 2;
    grid-row: 2;
    position: relative;
    overflow: auto;
}

.topbar,
.sidebar {
    padding: 10px;
}

.content {
    display: inline-flex;

    :global(.dark),
    :global(.light) {
        display: table; // Otherwise it doesn't stretch to 100% content 🤷‍♀️
        min-height: 100%;

        flex-grow: 1;
        padding: 10px 10px 20px;
        background-color: $color-background;
        color: $color-text;
    }
}
