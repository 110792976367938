@import 'styles/fonts.scss';

.badge {
    display: inline-flex;
    align-items: center;
    margin: 0;

    &.partOfList {
        margin: 0 5px 5px 0;
    }
}

.text {
    padding-top: 1px;

    @extend %montserrat-fat;
    font-weight: 700;
    font-size: 11px;
}

.button {
    display: flex;
    cursor: pointer;
    border: none;
    background: none;
    color: inherit;
    border-radius: 0;
    outline: none;
}

.buttonTooltip {
    margin-right: -8px;
    border-left: 1px solid white;
    margin-left: 9px;
}
