$topbarHeight: 60px;

:global(.dark) .wrapper {
    background-color: #262a35;
    border-bottom: 1px solid #232730;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 125px 1fr;
    grid-gap: 10px;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
}

.menuIcon {
    grid-column: 1;
    display: flex;
    margin: 0 12px;
    align-items: center;
}

.logo {
    grid-column: 2;
    align-self: end;
}

.profile {
    grid-column: 3;
    align-self: center;
    margin-right: 10px;
}

.search {
    margin-left: 10px;
}

.toggleTheme {
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
    margin: 0 12px;
    align-items: center;
}
