@import 'styles/colors.scss';

.categories {
    position: sticky;
    top: -1px;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    z-index: 1;
    background-color: $color-background;

    .button {
        $size: 35px;
        width: $size;
        height: $size;
        display: inline-block;
        margin-left: 5px;
        font-size: 18px;
        padding: 0;

        &:first-child {
            margin-left: 15px;
        }

        &:last-child {
            margin-right: 15px;
        }
    }
}

.category {
    display: flex;
    padding: 10px 0;

    &:not(:last-child) {
        border-bottom: 1px solid $color-border;
    }
}

.categoryKey {
    font-size: 25px;
    font-weight: 600;
    color: $color-link;
    font-family: 'Montserrat';
    width: 50px;
}

.list {
    flex-grow: 1;
}
