@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/spacings.scss';

$size: $spacing-4;

.list {
    list-style-type: none;
    list-style-type: decimal;
    margin: 0 0 $spacing-3 $spacing-4;
    padding: 0;
    counter-reset: li-counter;
}

.item {
    position: relative;
    padding: $spacing-1 $spacing-2;
    min-height: $spacing-5;
}

.item:before {
    @extend %montserrat-fat;
    position: absolute;
    left: -$size;
    width: $size;
    height: $size;
    text-align: center;
    line-height: $size;
    top: 3px;
    color: white;
    background-color: $color-green-500;
    content: counter(li-counter);
    counter-increment: li-counter;
    cursor: default;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.item:hover:before {
    transform: scale(1.2);
}
