@import 'styles/media.scss';

$topbarHeight: 59px;

.wrapper {
    display: grid;
    grid-template-columns: 95px 1fr;
    grid-gap: 0;
    grid-auto-rows: $topbarHeight + 1px calc(100vh - #{$topbarHeight + 1px});
}

.sidebar {
    grid-column: 1;
    grid-row: 1 / 3;
}

.content {
    grid-column: 2;
    grid-row: 2;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.innerContent {
    grid-column: 2;
    grid-row: 2;
    padding: 20px 30px;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
}

.topbar {
    grid-column: 2 / 3;
    grid-row: 1;
}

.spinner {
    display: none;
    position: absolute;
    top: $topbarHeight + 10px;
    right: 10px;
    pointer-events: none;
}

@include media-breakpoint-down(sm) {
    .wrapper {
        grid-template-columns: 0 1fr;
    }

    .innerContent {
        padding: 15px;
    }
}
