@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.button {
    @extend %montserrat-fat;
    font-size: 12px;

    border-radius: 2px;
    border: none;
    cursor: pointer;
    text-align: center;
    height: 35px;
    line-height: 21px;
    padding-left: 17px;
    padding-right: 17px;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
        opacity 0.2s linear 0s;
    outline: none;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.default {
    color: $color-link;
    background-color: $color-light-500;

    text-shadow: 1px 1px 0 $color-dynamic-light-shadow;

    &:hover {
        background-color: $color-light-600;
    }
}

.primary {
    color: #fff;
    background-color: $color-green-500;

    text-shadow: 1px 1px 0 $color-dark-shadow;

    &:hover {
        background-color: $color-green-600;
    }
}

.dark {
    color: $color-text-dark;
    background-color: $color-dark-500;

    text-shadow: 1px 1px 0 $color-dynamic-dark-shadow;

    &:hover {
        background-color: $color-dark-600;
    }
}
