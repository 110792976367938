.container {
    display: flex;
    align-items: center;
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat';
    color: #2c2c2c;

    a {
        color: #888;

        &:hover {
            color: #2c2c2c;
        }
    }

    svg {
        line-height: 5px;
        margin: -1px 1px;
        font-size: 16px;
    }

    :global(.dark) & {
        color: #d4d4d4;

        a {
            color: rgba(#d4d4d4, 0.6);
        }
    }
}
