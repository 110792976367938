@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.container {
    background-color: $color-light-400;
    border: 1px solid $color-border;
    border-radius: 2px;
    color: $color-text;
    min-height: 55px;
    position: relative;

    display: grid;
    grid-gap: 2px;
    grid-auto-rows: 1fr 27px;
    grid-template-columns: 1fr;

    &.withSpinner {
        grid-template-columns: 1fr 0;
    }
    &.withButton {
        grid-template-columns: 1fr 50px;
    }
    &:not(.withLabel) {
        min-height: 38px;
        grid-auto-rows: 1fr 1fr;
    }
}

.label {
    place-self: end left;
    grid-column: 1;
    grid-row: 1;

    margin: 10px 10px 0;

    @extend %montserrat-fat;
    font-weight: 700;
    font-size: 11px;
}

.input {
    place-self: start left;
    background-color: transparent;
    color: inherit;
    grid-column: 1;
    grid-row: 2;
    outline: 0;
    width: 100%;
    padding: 0 10px;
    height: 20px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    border: none;

    .container:not(.withLabel) & {
        grid-row: 1/3;
        margin: 10px 0;
    }
}

.spinner {
    place-self: center left;
    grid-column: 2;
    grid-row: 1/3;
    position: absolute;
    right: 10px;
    pointer-events: none;
}

.button {
    place-self: start left;
    grid-column: 2;
    grid-row: 1/3;

    background-color: $color-light-500;
    border: none;
    outline: 0;
    border-left: 1px solid $color-border;
    height: 100%;
    width: 100%;
    font-size: 16px;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $color-light-600;
    }
}
