@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/spacings.scss';
@import 'styles/animations.scss';

.trigger {
    display: inline-flex;
    cursor: default;
}

@keyframes top {
    from {
        transform: translateY(8px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes bottom {
    from {
        transform: translateY(-8px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes left {
    from {
        transform: translateX(8px);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes right {
    from {
        transform: translateX(-8px);
    }
    to {
        transform: translateX(0);
    }
}

.overlay {
    z-index: 1000;
    position: absolute;
    background-color: black;
    border-radius: 2px;
    color: white;
    padding: $spacing-1 $spacing-2;
    font-size: 13px;
    max-width: 300px;
    min-width: 50px;
    line-height: 20px;
    pointer-events: none;

    @extend %montserrat;
    font-weight: 500;

    animation-duration: 250ms, 500ms;
    &.top {
        animation-name: top, fade-in;
    }
    &.bottom {
        animation-name: bottom, fade-in;
    }
    &.left {
        animation-name: left, fade-in;
    }
    &.right {
        animation-name: right, fade-in;
    }
}
