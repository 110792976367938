@import 'styles/palette.scss';
@import 'styles/colors.scss';

:root,
.light {
    --light-400: #{$palette-light-400};
    --light-500: #{$palette-light-500};
    --light-600: #{$palette-light-600};

    --dark-400: #{$palette-dark-400};
    --dark-500: #{$palette-dark-500};
    --dark-600: #{$palette-dark-600};

    --light-shadow: #{$palette-light-shadow};
    --dark-shadow: #{$palette-dark-shadow};

    --background: #{$palette-background};
    --border: #{$palette-border};

    --color: #{$palette-text};
    --color-link: #{$palette-link};
    --color-link-hover: #{$palette-link-hover};

    --color-dark: #{$palette-dark-text};
    --color-dark-link: #{$palette-dark-link};
    --color-dark-link-hover: #{$palette-dark-link-hover};

    --input-background: white;
}

.dark {
    --light-400: #{$palette-dark-400};
    --light-500: #{$palette-dark-500};
    --light-600: #{$palette-dark-600};

    --dark-400: #{$palette-light-400};
    --dark-500: #{$palette-light-500};
    --dark-600: #{$palette-light-600};

    --light-shadow: #{$palette-dark-shadow};
    --dark-shadow: #{$palette-light-shadow};

    --background: #{$palette-dark-background};
    --border: #{$palette-dark-border};

    --color: #{$palette-dark-text};
    --color-link: #{$palette-dark-link};
    --color-link-hover: #{$palette-dark-link-hover};

    --color-dark: #{$palette-text};
    --color-dark-link: #{$palette-link};
    --color-dark-link-hover: #{$palette-link-hover};

    --input-background: #{$palette-dark-600};
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    color: $color-text;
    font-family: 'Open Sans', 'Segoe UI', Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.01em;
    display: block;
    background-color: $color-background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    font-weight: 600;
    color: $color-link;
    font-family: 'Montserrat';
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
        opacity 0.2s linear 0s;

    &:hover {
        color: $color-link-hover;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-weight: 300;
    margin-top: 0;
}

h1 {
    font-size: 44px;
    line-height: 55px;
    letter-spacing: -0.08px;
    font-weight: 300;
}

h2 {
    font-size: 31px;
    line-height: 40px;
    font-weight: 400;
}

h3 {
    font-size: 27px;
    line-height: 35px;
}

h4 {
    font-size: 22px;
    line-height: 31.88px;
    font-style: italic;
    font-weight: 300;
}

h5 {
    font-size: 18px;
    line-height: 25.88px;
}

.center {
    text-align: center;
}

hr {
    border: unset;
    border-top: 1px solid #e6e6e6;
    margin: 15px 0;
}
