@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.container {
    position: relative;

    ul {
        position: absolute;
        z-index: 1;
        width: 100%;
        margin: 0;
        padding: 8px 0;
        background-color: $color-light-500;
        border: 1px solid $color-border;
        border-top: 0;
        font-size: 13px;
        overflow: auto;
        max-height: unquote('min(100vh, 264px)');
    }

    li {
        list-style: none;
        cursor: pointer;
        line-height: 16px;
        padding: 4px 12px;

        &.active,
        &:hover {
            background-color: $color-light-600;
        }
    }
}
