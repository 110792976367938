.container {
    max-width: 450px;
    margin: 0 auto;
}

.fromTo {
    margin-top: 24px;
    display: flex;
}

.convertionItem {
    flex-grow: 1;
}

.equal {
    margin: 0 8px;
    font-size: 26px;
    padding-top: 2px;
}

.input {
    border-bottom: 0;

    input {
        text-align: center;
        font-size: 20px;
    }
}
