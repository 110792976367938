@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/spacings.scss';
@import 'styles/animations.scss';
@import 'styles/aspect-ratio.scss';
@import 'styles/media.scss';

$transition-in: 0.25s ease-in-out;
$transition-out: 0.75s ease-in-out;

:global(.dark) .container {
    background-color: #2a2f3b;
}

.container {
    @include aspect-ratio(40, 25);
    position: relative;
    display: inline-flex;

    width: 100%;
    max-width: 400px;

    background-color: #e6e6e6;
    margin-bottom: 10px;
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: #2a2f3b;

    transition: opacity $transition-out;
    opacity: 0;
    .container:hover & {
        transition: opacity $transition-in;
        opacity: 0.1;
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.title {
    position: absolute;
    bottom: 20px;
    width: 100%;

    display: block;
    color: #2c2c2c;
    font-family: 'Montserrat';
    font-weight: 500;
    padding: 15px 20px;
    font-size: 16px;

    text-shadow: 1px 1px 0 rgba(white, 0.3);

    transition: all $transition-out;
    background-color: rgba(white, 0.5);
    .container:hover & {
        transition: all $transition-in;
        background-color: rgba(white, 0.7);
    }
}

@include media-breakpoint-only(xs) {
    .container {
        max-width: unset;
        width: 100%;
    }
}

@include media-breakpoint-between(sm, md) {
    .container {
        width: calc(50% - 5px);

        &:nth-child(even) {
            margin-left: 5px;
        }

        &:nth-child(odd) {
            margin-right: 5px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .container {
        width: calc(33.3% - 6.6px);

        &:nth-child(3n + 1) {
            margin-right: 6.6px;
        }

        &:nth-child(3n + 2) {
            margin: 0 3.3px;
        }

        &:nth-child(3n + 3) {
            margin-left: 6.6px;
        }
    }
}
