@import 'styles/colors.scss';

$size: 20px;

.badge {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    padding: 0;
    font-weight: 600;
    margin-left: 4px;
    vertical-align: baseline;

    h1 &,
    h2 &,
    h3 &,
    h4 & {
        vertical-align: top;
    }
}

.primary {
    background-color: #6abb6e;
    color: #fff;
}

.default {
    background-color: $color-light-600;
    color: $color-text;
}

.round {
    border-radius: 50%;
    width: $size;
    height: $size;
}

.pill {
    border-radius: $size / 2;
    height: $size;
    min-width: $size;
    padding: 0 6px;
}

.square {
    border-radius: 2px;
    width: $size;
    height: $size;
}

.rect {
    border-radius: 2px;
    height: $size;
    padding: 0 8px;
}
