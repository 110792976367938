@import 'styles/colors.scss';
@import 'styles/fonts.scss';

.container {
    background-color: $color-light-400;
    border: 1px solid $color-border;
    border-radius: 2px;
    color: $color-text;
    position: relative;

    display: flex;
    flex-wrap: wrap;

    min-height: 38px;

    &.withLabel {
        min-height: 55px;
    }
}

.label {
    width: 100%;
    padding: 10px 10px 0;

    @extend %montserrat-fat;
    font-weight: 700;
    font-size: 11px;
}

.select {
    background-color: transparent;
    color: inherit;
    outline: 0;
    width: 100%;
    margin: 8px 10px;
    height: 20px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    border: none;

    .withLabel & {
        margin: 2px 10px 7px;
    }

    -moz-appearance: none;
    -webkit-appearance: none;
}

.icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    pointer-events: none;
    transition: all 0.2s ease;
}
